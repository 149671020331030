import { Component } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import txnLoading from "../../../assets/progressSpinner.gif";
import { getUser } from "../../../services/tokenService";
import { commonConfigs, urls } from "../../../config/config";
import netsQrInfo from "../../../assets/netsQrInfo.png";
import { EventSourcePolyfill } from "event-source-polyfill";
import { getDoublewriteConfig } from "../../../commons/utils/getDoublewriteConfig";
import { DEVELOPER_PROJECT_STATUS } from "../../../commons/constants/developerProjStatus";

const user = getUser();
const requestNetsUrl =
  urls.resourceOwnerUrl + commonConfigs.apiUrls.requestNetsApi();
const queryNetsUrl =
  urls.resourceOwnerUrl + commonConfigs.apiUrls.queryNetsApi();

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      convertTime: {},
      secondsNetsTimeout: 300,
      amount: localStorage.getItem("amount"),
      txnId: localStorage.getItem("txnId"),
      mobile: parseInt(user.mobile),
      projId: localStorage.getItem("projId"),
      invoicePlanId: localStorage.getItem("planId"),
      netsQrPayment: txnLoading,
      netsQrRetrievalRef: "",
      netsQrGenerate: false,
      netsQrDisplayLogo: false,
      netsQrResponseCode: "",
      openApiPaasTxnStatus: 0,
      networkCode: "",
      instructions: "",
      errorMsg: "",
    };
    this.netsTimer = 0;

    this.queryNets = this.queryNets.bind(this);
    this.startNetsTimer = this.startNetsTimer.bind(this);
    this.decrementNetsTimer = this.decrementNetsTimer.bind(this);
  }
  async requestNets(amt, txnId, mobile) {
    this.setState({ netsQrGenerate: true });
    var body = {
      txn_id: txnId,
      amt_in_dollars: amt,
      notify_mobile: mobile,
    };
    await axios
      .post(requestNetsUrl, body, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        var response = res.data.result.data;
        if (
          response.response_code == "00" &&
          response.txn_status == 1 &&
          response.qr_code !== "" &&
          response.qr_code !== null
        ) {
          localStorage.setItem("txnRetrievalRef", response.txn_retrieval_ref);
          this.startNetsTimer();
          this.setState({
            netsQrResponseCode: response.response_code,
            netsQrPayment: "data:image/png;base64," + response.qr_code,
            netsQrRetrievalRef: response.txn_retrieval_ref,
            networkCode: response.network_status,
            openApiPaasTxnStatus: response.txn_status,
          });
          this.webhookNets();
        } else {
          this.setState({
            netsQrResponseCode:
              response.response_code === "" ? "N.A." : response.response_code,
            netsQrPayment: "",
            instructions:
              response.network_status == 0 ? response.instruction : "",
            errorMsg:
              response.network_status !== 0
                ? "Something went wrong. Please try again."
                : "",
            networkCode: response.network_status,
            openApiPaasTxnStatus: response.txn_status,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/fail";
      });
  }

  webhookNets() {
    if (this.s2sNetsTxnStatus) {
      this.s2sNetsTxnStatus.close();
    }
    const url =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.webhookNetsApi(
        localStorage.getItem("txnRetrievalRef")
      );
    this.s2sNetsTxnStatus = new EventSourcePolyfill(url, {
      headers: commonConfigs.resourceOwnerApiHeader,
      heartbeatTimeout: 150000,
    });

    this.s2sNetsTxnStatus.addEventListener("message", async (event) => {
      const data = JSON.parse(event.data);
      if (data.message === "QR code scanned") {
        if (this.s2sNetsTxnStatus) {
          this.s2sNetsTxnStatus.close();
        }
        if (this.state.invoicePlanId && this.state.projId) {
          await this.createDeveloperCourse();
        } else {
          if (localStorage.getItem("voucher")) {
            // If have voucher, update new nett amt after voucher
            await this.updateInvoice();
          } else {
            localStorage.removeItem("txnId");
            localStorage.removeItem("amount");
            localStorage.removeItem("voucher");
            localStorage.removeItem("invoiceId");
            window.location.href = "/success";
          }
        }
      } else if (data.message === "Timeout") {
        if (this.s2sNetsTxnStatus) {
          this.s2sNetsTxnStatus.close();
        }
        this.queryNets();
      }
    });
  }
  async updateDoublewriteDevProj(expiryDate) {
    const { url, headers } = getDoublewriteConfig(process.env.REACT_APP_ENV);
    const doublewriteUrl =
      url + commonConfigs.apiUrls.UpdateDeveloperProject(this.state.projId);
    const body = {
      developer_proj_status: DEVELOPER_PROJECT_STATUS.Active,
      developer_proj_expiry: expiryDate,
    };
    try {
      await axios.put(doublewriteUrl, body, { headers: headers });
    } catch (error) {
      console.error("Error updating doublewrite project:", error);
    }
  }
  async updateInvoice() {
    let body= {}
    const getVoucherUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getVoucher(localStorage.getItem("voucher"));
    await axios
      .get(getVoucherUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        try {
          body = {
            voucher_syscode: res.data.result.data.voucher_syscode,
            voucher_code: localStorage.getItem("voucher"),
            voucher_amt: res.data.result.data.voucher_value,
          };
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        throw err;
      });
    const updateInvoiceUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.updateInvoice(localStorage.getItem("invoiceId"));
    await axios
      .put(updateInvoiceUrl, body, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        localStorage.removeItem("txnId");
        localStorage.removeItem("amount");
        localStorage.removeItem("voucher");
        localStorage.removeItem("invoiceId");
        window.location.href = "/success";
      })
      .catch((error) => {
        console.log("Error: ", error);
        window.location.href = "/fail";
      });
  }
  async createDeveloperCourse() {
    const createDeveloperCourseUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.createDeveloperCourse(user.account_id);

    const body = {
      developer_proj_id: this.state.projId,
      invoice_plan_id: this.state.invoicePlanId,
    };
    await axios
      .post(createDeveloperCourseUrl, body, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then(async (res) => {
        // console.log(res.data.result.data);
        await this.updateDoublewriteDevProj(res.data.result.data.course_expiry);
        localStorage.removeItem("projId");
        localStorage.removeItem("planId");
        localStorage.removeItem("txnId");
        localStorage.removeItem("amount");
        window.location.href = "/success";
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/fail";
      });
  }
  async queryNets() {
    var netsTimeoutStatus = 0;
    if (this.state.secondsNetsTimeout == 0) {
      netsTimeoutStatus = 1;
    }

    if (this.state.netsQrRetrievalRef) {
      var body = {
        txn_retrieval_ref: this.state.netsQrRetrievalRef,
        frontend_timeout_status: netsTimeoutStatus,
      };
      await axios
        .post(queryNetsUrl, body, {
          headers: commonConfigs.resourceOwnerApiHeader,
        })
        .then((res) => {
          var response = res.data.result.data;
          console.log(response);

          if (response.response_code == "00" && response.txn_status == 1) {
            window.location.href = "/success";
          } else {
            window.location.href = "/fail";
          }
        })
        .catch((err) => {
          console.log(err);
          window.location.href = "/fail";
        });
    }
  }

  startNetsTimer() {
    if (this.netsTimer == 0 && this.state.secondsNetsTimeout > 0) {
      this.netsTimer = setInterval(this.decrementNetsTimer, 1000);
    }
  }
  convertTimeFormat(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let secondsNetsTimeout = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: secondsNetsTimeout,
    };
    return obj;
  }
  decrementNetsTimer() {
    let secondsNetsTimeout = this.state.secondsNetsTimeout - 1;
    this.setState({
      convertTime: this.convertTimeFormat(secondsNetsTimeout),
      secondsNetsTimeout: secondsNetsTimeout,
    });

    if (secondsNetsTimeout == 0) {
      clearInterval(this.netsTimer);
    }
  }

  componentDidMount() {
    this.requestNets(this.state.amount, this.state.txnId, this.state.mobile);
  }

  handleNetsCancel() {
    this.setState({ netsQrRetrievalRef: "" });
    this.setState({ netsQrDisplayLogo: false });
    localStorage.removeItem("txnId");
    localStorage.removeItem("amount");
    localStorage.removeItem("voucher");
    localStorage.removeItem("invoiceId");
    window.location.href = "/subscriptions/pricing-plan";
  }

  render() {
    return (
      <div>
        <div
          className="content"
          style={{
            padding: "10%",
            paddingBottom: "3%",
            paddingTop: "3%",
            margin: "auto",
            width: "60%",
          }}
        >
          {this.state.instructions === "" &&
          this.state.errorMsg === "" &&
          this.state.netsQrGenerate === true ? (
            <div className="successPayment" style={{ padding: "10px" }}>
              <h2
                className="text"
                style={{ fontSize: "24px", marginBottom: "10px" }}
              >
                SCAN TO PAY
              </h2>
              <p
                className="text"
                style={{ fontSize: "20px", fontWeight: "300" }}
              >
                Scan with your your DBS, OCBC, UOB, NetsPay app to complete your
                payment
              </p>
              <span>
                {this.state.netsQrPayment !== "" && (
                  <>
                    <div id="netsQrPayment" className="netsQrCode mt-4">
                      <img
                        id="imgNetsQr"
                        height="auto"
                        width="30%"
                        src={this.state.netsQrPayment}
                        style={{ margin: "0 auto" }}
                      />
                    </div>
                    <h2 className="netsTimer mt-4" style={{ fontSize: "16px" }}>
                      {this.state.convertTime.m} : {this.state.convertTime.s}
                    </h2>
                  </>
                )}
              </span>
              <img
                id="netsQrInfo"
                height="auto"
                width="40%"
                src={netsQrInfo}
                className="mt-4"
                style={{ margin: "0 auto" }}
              />
              <div
                className="button"
                id="btnCancel"
                style={{ marginTop: "20px" }}
              >
                <Button
                  variant="contained"
                  sx={{ width: 300 }}
                  style={{
                    backgroundColor: "#E02020",
                    borderRadius: "10px",
                    fontSize: "14px",
                    textDecoration: "none",
                    height: "50px",
                  }}
                  onClick={() => this.handleNetsCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className="netsQrPaymentGatewayWebpage">
              {this.state.netsQrResponseCode !== "00" &&
                this.state.netsQrResponseCode !== "" &&
                this.state.openApiPaasTxnStatus !== 1 && (
                  <>
                    <h2
                      className="text"
                      style={{ fontSize: "42px", marginBottom: "10px" }}
                    >
                      NETS Response Code: {this.state.netsQrResponseCode}
                    </h2>
                    {this.state.instructions !== "" ||
                    this.state.errorMsg !== "" ? (
                      <>
                        <h2 className="text" style={{ fontSize: "42px" }}>
                          {this.state.instructions}
                        </h2>
                        <h2 className="text" style={{ fontSize: "42px" }}>
                          {this.state.errorMsg}
                        </h2>
                      </>
                    ) : (
                      <h2
                        className="text"
                        style={{ fontSize: "42px", marginBottom: "10px" }}
                      >
                        System Error
                      </h2>
                    )}
                    <Button
                      variant="contained"
                      sx={{ width: 300 }}
                      style={{
                        backgroundColor: "#E02020",
                        borderRadius: "10px",
                        fontSize: "14px",
                        textDecoration: "none",
                        height: "50px",
                        marginTop: "30px",
                      }}
                      onClick={() => this.handleNetsCancel()}
                    >
                      Cancel
                    </Button>
                  </>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Payment;
