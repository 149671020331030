import { useMemo, useState } from "react";
import Spinner from "../../../../../commons/components/spinner";
import Table from "../../../../../commons/components/table";
import { useNavigate } from "react-router-dom";
import ComingSoonModal from "../../../../../commons/components/comingSoonModal";
import TechSupportModal from "../tech_support/techSupportModal";

const mockData = [
  {
    project_api_plan_id: "devApiPlanId1",
    project_name: "OpenApiPaaS Integration",
    on_demand_plan: "$500",
    monthly_plan: "$120",
    annual_plan: "$1320",
    is_deleted: 0,
    tech_support_plan: "OpenApiPaaS Integration",
    features: [
      "OpenApiPaaS Integration",
      "NETS QR Integration",
      { title: "Supported Payment Methods", items: ["eNETS", "NETS Click", "NETS QR"] },
    ],
  },
  {
    project_api_plan_id: "devApiPlanId2",
    project_name: "NETS QR Integration",
    on_demand_plan: "$150",
    monthly_plan: "$50",
    annual_plan: "$550",
    is_deleted: 0,
    tech_support_plan: "NETS QR Integration",
    features: [
      "NETS QR Integration",
      "Integration with NETS QR APIs",
      { title: "Supported Payment Methods", items: ["eNETS", "NETS QR"] },
    ],
  },
  {
    project_api_plan_id: "devApiPlanId3",
    project_name: "NETS QR Certification",
    on_demand_plan: "$150",
    monthly_plan: "$50",
    annual_plan: "$550",
    is_deleted: 0,
    tech_support_plan: "NETS QR Certification",
    features: [
      "NETS QR Certification",
      "Support for NETS QR APIs",
      { title: "Supported Payment Methods", items: ["eNETS", "NETS QR"] },
    ],
  },
  // Add more mock data as needed...
];

export default function TechSupportPlanLayout() {
  const [techSupportPlans, setTechSupportPlans] = useState(mockData);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [selectedPlanName, setSelectedPlanName] = useState(""); // To store the plan name for the modal
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      Header: "Tech Support Plan",
      accessor: "tech_support_plan",
    },
    {
      Header: "On-Demand Plan",
      accessor: "on_demand_plan",
    },
    {
      Header: "Monthly Plan",
      accessor: "monthly_plan",
    },
    {
      Header: "Annual Plan",
      accessor: "annual_plan",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <button
          type="button"
          onClick={() => handleGetStarted(row.original.project_name)}
          className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 py-2 px-12 rounded-full"
        >
          Get Started
        </button>
      ),
    },
  ], []);

  const handleGetStarted = (planName) => {
    setSelectedPlanName(planName);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handlePay = () => {
    console.log(`Plan selected: ${selectedPlanName}`);
    setOpen(false);
    // Add your payment logic here
  };

  return (
    <div className="w-full">
      {techSupportPlans.length > 0 ? (
        <>
          <Table
            columns={columns}
            data={techSupportPlans}
            filters={columns.map((column) => column.accessor)}
          />
        </>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
      <TechSupportModal
        isOpen={isOpen}
        setOpenModal={setOpen}
        planName={selectedPlanName}
      />
    </div>
  );
}