import {
  Building, Setting2,
} from "iconsax-react";
import { getUser } from "../../../services/tokenService"

const user = getUser();
if (user) {
  var mobileNum = user.mobile;
}

const navigation = [
{
  title: "Homepage",
  path: "/homepage",
  icon: Building,
  current: true,
},
{
  title: "Project",
  path: "/projects",
  icon: Building,
  current: true,
},
{
  title: "Access Roles",
  path: "/accessroles",
  icon: Building,
  current: true,
},
{
  title: "Navigation Page Access",
  path: "/accesspages",
  icon: Building,
  current: true,
},
{
  title: "Portfolio",
  path: `/portfolios`,
  icon: Building,
  current: true,
},
{
  title: "Invoices",
  path: "/invoices",
  icon: Building,
  current: true,
},
{
  title: "Resources",
  path: "#0",
  icon: Building,
  subNav: [
    {
      title: "HMAC-SHA256 Simulator",
      path: "/resources/hmac-simulator",
    },
  ]
},
{
  title: "Keys",
  path: "/developer/keys",
  icon: Building,
  current: true,
},
{
  title: "API Marketplace",
  path: "/developer/marketplace",
  icon: Building,
  current: true,
},
{
  title: "Billing",
  path: "/developer/billing",
  icon: Building,
  current: true,
},
{
  title: "Pricing Plan",
  path: "/subscriptions/pricing-plan",
  icon: Building,
  current: true,
},
{
  title: "Configurations",
  path: "/resource-groups/configurations/projects",
  icon: Setting2,
  subNav: [
    {
      title: "Projects",
      path: "/resource-groups/configurations/projects",
    },
    {
      title: "Project Billing Config",
      path: "/resource-groups/configurations/billings",
    },
    {
      title: "Subscription Plans",
      path: "/resource-groups/configurations/subscriptions",
    },
  ]
}
];

export default navigation;
