import { useContext, useState } from "react";
import CertificationInvoice from "../components/certification/certificationInvoice";
import InvoiceMenuTabs from "../components/invoiceMenuTabs";
import ProductionInvoice from "../components/production/productionInvoice";
import StagingInvoice from "../components/staging/stagingInvoice";
import { invoiceCurrentTabContext } from "../context/invoiceContext";
import TechSupportInvoice from "../components/tech_support/techSupportInvoice";

const InvoicesLayout = () => {
  const [load, setLoad] = useState(false);
  const { currentTab } = useContext(invoiceCurrentTabContext);

  if (!load) {
    return (
      <div className="w-full p-6">
        <div className="md:flex md:items-center md:justify-between">
          <h2
            style={{
              marginLeft: "8px",
              fontSize: "18px",
              marginBottom: "25px",
              fontWeight: "bold",
              color: "#2A3042",
            }}
          >
            My Invoices
          </h2>
        </div>

        <div>
          <div
            className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2"
            // style={{ marginBottom: "-20px" }}
          >
            <InvoiceMenuTabs />
          </div>
          {currentTab.name == "Staging" && (
            <>
              {/* <StagingInvoice /> */}
              <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                <div className="py-16">
                  <div className="text-center">
                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                      Coming Soon
                    </h1>
                  </div>
                </div>
              </div>
            </>
          )}
          {currentTab.name == "Production" && (
            <>
              <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                <div className="py-16">
                  <div className="text-center">
                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                      Coming Soon
                    </h1>
                  </div>
                </div>
              </div>
              {/* <ProductionInvoice /> */}
            </>
          )}
          {currentTab.name == "Tech Support" && (
            <>
              {/* <TechSupportInvoice /> */}
              <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                <div className="py-16">
                  <div className="text-center">
                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                      Coming Soon
                    </h1>
                  </div>
                </div>
              </div>
            </>
          )}
          {currentTab.name == "Certification" && (
            <>
              <CertificationInvoice />
            </>
          )}
          {currentTab.name == "Membership" && (
            <>
              <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                <div className="py-16">
                  <div className="text-center">
                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                      Coming Soon
                    </h1>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default InvoicesLayout;
