import { useState, useEffect, useRef } from "react";

export default function PlanCardModal({ setOpenModal, selectedPlan }) {
    const [selectedOption, setSelectedOption] = useState("course");

    const [courseCode, setCourseCode] = useState("");
    const [voucherCode, setVoucherCode] = useState("");

    const [savedCourseCodes, setSavedCourseCodes] = useState([]);
    const [savedVoucherCodes, setSavedVoucherCodes] = useState([]);

    const [courseApplied, setCourseApplied] = useState(false);
    const [voucherApplied, setVoucherApplied] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showCourseDropdown, setShowCourseDropdown] = useState(false);
    const [showVoucherDropdown, setShowVoucherDropdown] = useState(false);

    // 🔹 Case-Sensitive Mock Database of Valid Codes
    const validCourseCodes = ["2024SEP", "2024OCT"];
    const validVoucherCodes = ["VOUCHER123", "DISCOUNT50"];

    // 🔹 Refs for detecting clicks outside dropdowns
    const courseDropdownRef = useRef(null);
    const voucherDropdownRef = useRef(null);

    // 🔹 Load previously entered codes from localStorage
    useEffect(() => {
        const storedCourses = JSON.parse(localStorage.getItem("savedCourseCodes")) || [];
        const storedVouchers = JSON.parse(localStorage.getItem("savedVoucherCodes")) || [];
        setSavedCourseCodes(storedCourses);
        setSavedVoucherCodes(storedVouchers);
    }, []);

    // 🔹 Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (courseDropdownRef.current && !courseDropdownRef.current.contains(event.target)) {
                setShowCourseDropdown(false);
            }
            if (voucherDropdownRef.current && !voucherDropdownRef.current.contains(event.target)) {
                setShowVoucherDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleApplyCourseCode = () => {
        if (!savedCourseCodes.includes(courseCode)) {
            const updatedCourses = [...new Set([...savedCourseCodes, courseCode])];
            setSavedCourseCodes(updatedCourses);
            localStorage.setItem("savedCourseCodes", JSON.stringify(updatedCourses));
        }

        if (validCourseCodes.includes(courseCode)) {
            setCourseApplied(true);
            setVoucherApplied(false);
            setErrorMessage("");
        } else {
            setErrorMessage("Incorrect course code applied! (Case-sensitive)");
        }
    };

    const handleApplyVoucherCode = () => {
        if (!savedVoucherCodes.includes(voucherCode)) {
            const updatedVouchers = [...new Set([...savedVoucherCodes, voucherCode])];
            setSavedVoucherCodes(updatedVouchers);
            localStorage.setItem("savedVoucherCodes", JSON.stringify(updatedVouchers));
        }

        if (validVoucherCodes.includes(voucherCode)) {
            setVoucherApplied(true);
            setCourseApplied(false);
            setErrorMessage("");
        } else {
            setErrorMessage("Incorrect voucher code applied! (Case-sensitive)");
        }
    };

    const handleProceed = () => {
        console.log(`Plan: ${selectedPlan}, Course: ${courseCode}, Voucher: ${voucherCode}`);
        setOpenModal(false);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 shadow-lg w-96 text-black">
                <h2 className="text-lg font-bold mb-4 text-center">Redeem a Code or Continue</h2>

                {/* Error & Success Messages */}
                {errorMessage && (
                    <div className="bg-red-500 text-white text-sm p-2 rounded mb-2 text-center">
                        {errorMessage}
                    </div>
                )}
                {courseApplied && (
                    <div className="bg-green-500 text-white text-sm p-2 rounded mb-2 text-center">
                        Course Code Applied Successfully!
                    </div>
                )}
                {voucherApplied && (
                    <div className="bg-green-500 text-white text-sm p-2 rounded mb-2 text-center">
                        Voucher Code Applied Successfully!
                    </div>
                )}

                {/* Radio Buttons */}
                <div className="space-y-4">
                    {/* Redeem Course Code */}
                    <div>
                        <label className={`flex items-center space-x-2 text-black ${voucherApplied ? "opacity-50 cursor-not-allowed" : ""}`}>
                            <input
                                type="radio"
                                value="course"
                                checked={selectedOption === "course"}
                                onChange={() => setSelectedOption("course")}
                                disabled={voucherApplied}
                            />
                            <span>Redeem Course Code</span>
                        </label>

                        {selectedOption === "course" && (
                            <div className="mt-2 flex items-center space-x-2 relative">
                                <div className="relative w-full" ref={courseDropdownRef}>
                                    <input
                                        type="text"
                                        className="border px-3 py-2 w-full text-black bg-white"
                                        placeholder="Enter course code"
                                        value={courseCode}
                                        onChange={(e) => setCourseCode(e.target.value)}
                                        onFocus={() => !courseApplied && setShowCourseDropdown(true)}
                                        disabled={voucherApplied || courseApplied}
                                    />

                                    {/* Dropdown */}
                                    {!courseApplied && showCourseDropdown && savedCourseCodes.length > 0 && (
                                        <ul className="absolute left-0 w-full mt-1 bg-white border border-gray-300 shadow-lg max-h-40 overflow-y-auto rounded-md">
                                            {savedCourseCodes.map((code, index) => (
                                                <li
                                                    key={index}
                                                    className="p-2 hover:bg-gray-100 cursor-pointer transition"
                                                    onClick={() => {
                                                        setCourseCode(code);
                                                        setShowCourseDropdown(false);
                                                    }}
                                                >
                                                    {code}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                {!courseApplied && (
                                    <button className="bg-blue-500 text-white px-4 py-2" onClick={handleApplyCourseCode} disabled={voucherApplied}>
                                        Apply
                                    </button>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Redeem Voucher Code */}
                    <div>
                        <label className={`flex items-center space-x-2 text-black ${courseApplied ? "opacity-50 cursor-not-allowed" : ""}`}>
                            <input
                                type="radio"
                                value="voucher"
                                checked={selectedOption === "voucher"}
                                onChange={() => setSelectedOption("voucher")}
                                disabled={courseApplied}
                            />
                            <span>Redeem Voucher Code</span>
                        </label>

                        {selectedOption === "voucher" && (
                            <div className="mt-2 flex items-center space-x-2 relative">
                                <div className="relative w-full" ref={voucherDropdownRef}>
                                    <input
                                        type="text"
                                        className="border px-3 py-2 w-full text-black bg-white"
                                        placeholder="Enter voucher code"
                                        value={voucherCode}
                                        onChange={(e) => setVoucherCode(e.target.value)}
                                        onFocus={() => !voucherApplied && setShowVoucherDropdown(true)}
                                        disabled={courseApplied || voucherApplied}
                                    />

                                    {/* Dropdown */}
                                    {!voucherApplied && showVoucherDropdown && savedVoucherCodes.length > 0 && (
                                        <ul className="absolute left-0 w-full mt-1 bg-white border border-gray-300 shadow-lg max-h-40 overflow-y-auto rounded-md">
                                            {savedVoucherCodes.map((code, index) => (
                                                <li
                                                    key={index}
                                                    className="p-2 hover:bg-gray-100 cursor-pointer transition"
                                                    onClick={() => {
                                                        setVoucherCode(code);
                                                        setShowVoucherDropdown(false);
                                                    }}
                                                >
                                                    {code}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                {!voucherApplied && (
                                    <button className="bg-blue-500 text-white px-4 py-2" onClick={handleApplyVoucherCode} disabled={courseApplied}>
                                        Apply
                                    </button>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Continue Without Code */}
                    <div>
                        <label className={`flex items-center space-x-2 text-black ${courseApplied || voucherApplied ? "opacity-50 cursor-not-allowed" : ""}`}>
                            <input
                                type="radio"
                                value="continue"
                                checked={selectedOption === "continue"}
                                onChange={() => setSelectedOption("continue")}
                                disabled={courseApplied || voucherApplied}
                            />
                            <span>Continue without code</span>
                        </label>
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex justify-between mt-6">
                    <button className="border border-red-500 text-red-500 px-4 py-2 w-1/2" onClick={() => setOpenModal(false)}>Cancel</button>
                    <button className="bg-blue-500 text-white px-4 py-2 w-1/2" onClick={handleProceed}>Proceed</button>
                </div>
            </div>
        </div>
    );
}
