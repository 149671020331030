import React, { useState } from "react";

export default function TechSupportModal({ isOpen, setOpenModal, planName }) {
    const [selectedPlan, setSelectedPlan] = useState("");  // Set initial value as empty string
    const [voucherCode, setVoucherCode] = useState("");

    const handlePlanChange = (e) => {
        setSelectedPlan(e.target.value);
    };

    const handleVoucherChange = (e) => {
        setVoucherCode(e.target.value);
    };

    const handleApplyVoucher = () => {
        console.log("Voucher applied:", voucherCode);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handlePay = () => {
        console.log(`Plan: ${selectedPlan}, Voucher: ${voucherCode}`);
        setOpenModal(false);
        // Add payment functionality here
    };

    if (!isOpen) return null;

    const modalBackgroundStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    };

    const modalContentStyle = {
        backgroundColor: "white",
        width: "500px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    };

    const headerStyle = {
        fontSize: "20px",
        fontWeight: "600",
        marginBottom: "10px",
    };

    const formGroupStyle = {
        marginBottom: "15px",
    };

    const labelStyle = {
        fontWeight: "500",
        marginBottom: "5px",
        display: "block",
    };

    const selectInputStyle = {
        width: "100%",
        padding: "10px",
        marginTop: "5px",
        borderRadius: "5px",
        border: "1px solid #ccc",
    };

    const voucherInputStyle = {
        width: "80%",
        padding: "10px",
        marginTop: "5px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        marginRight: "10px",
    };

    const applyButtonStyle = {
        backgroundColor: "transparent",
        border: "2px solid #007BFF",
        color: "#007BFF",
        padding: "10px",
        marginTop: "5px",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "background-color 0.3s, color 0.3s",
    };

    const applyButtonHoverStyle = {
        backgroundColor: "#007BFF",
        color: "white",
    };

    const footerStyle = {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
    };

    const actionButtonStyle = {
        padding: "10px 20px",
        border: "2px solid",
        borderRadius: "5px",
        cursor: "pointer",
        marginLeft: "10px",
        transition: "background-color 0.3s, color 0.3s",
    };

    const cancelButtonStyle = {
        borderColor: "#f44336",
        color: "#f44336",
    };

    const payButtonStyle = {
        borderColor: "#4CAF50",
        color: "#4CAF50",
    };

    const cancelButtonHoverStyle = {
        backgroundColor: "#f44336",
        color: "white",
    };

    const payButtonHoverStyle = {
        backgroundColor: "#4CAF50",
        color: "white",
    };

    return (
        <div style={modalBackgroundStyle}>
            <div style={modalContentStyle}>
                <div style={headerStyle}>
                    <h2>Select Plan for {planName}</h2>
                </div>
                <div style={formGroupStyle}>
                    <label style={labelStyle}>Pricing Plan</label>
                    <select
                        value={selectedPlan}
                        onChange={handlePlanChange}
                        style={selectInputStyle}
                    >
                        <option value="" disabled selected>
                            Select a Pricing Plan
                        </option>
                        <option value="On-Demand Plan">On-Demand Plan</option>
                        <option value="Monthly Plan">Monthly Plan</option>
                        <option value="Annual Plan">Annual Plan</option>
                    </select>
                </div>
                <div style={formGroupStyle}>
                    <label style={labelStyle}>Voucher Code (Optional)</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="text"
                            placeholder="Enter voucher code"
                            value={voucherCode}
                            onChange={handleVoucherChange}
                            style={voucherInputStyle}
                        />
                        <button
                            onClick={handleApplyVoucher}
                            style={applyButtonStyle}
                            onMouseOver={(e) =>
                                (e.target.style.backgroundColor = applyButtonHoverStyle.backgroundColor)
                            }
                            onMouseOut={(e) =>
                                (e.target.style.backgroundColor = "transparent")
                            }
                        >
                            Apply
                        </button>
                    </div>
                </div>
                <div style={footerStyle}>
                    <button
                        onClick={handleClose}
                        style={{ ...actionButtonStyle, ...cancelButtonStyle }}
                        onMouseOver={(e) =>
                            (e.target.style.backgroundColor = cancelButtonHoverStyle.backgroundColor)
                        }
                        onMouseOut={(e) =>
                            (e.target.style.backgroundColor = "transparent")
                        }
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handlePay}
                        style={{ ...actionButtonStyle, ...payButtonStyle }}
                        onMouseOver={(e) =>
                            (e.target.style.backgroundColor = payButtonHoverStyle.backgroundColor)
                        }
                        onMouseOut={(e) =>
                            (e.target.style.backgroundColor = "transparent")
                        }
                    >
                        Pay
                    </button>
                </div>
            </div>
        </div>
    );
}
