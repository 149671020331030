import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { commonConfigs, urls } from "../../../../../../config/config";
import { getUser } from "../../../../../../services/tokenService";

export default function InvoiceDetailsLayout() {
  const location = useLocation();
  const data = location.state?.data;
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherAmt, setVoucherAmt] = useState(0);
  const [isVoucherApplied, setIsVoucherApplied] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const accountId = getUser()?.account_id;
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({});
  const billingFields = [
    {
      label: "Due Date",
      value: new Date(data.billing_summary.invoice_due_datetime)
        .toISOString()
        .split("T")[0],
    },
    { label: "Billing Period", value: data.billing_summary.billing_period },
    {
      label: "Sub Total Amount",
      value: `$${data.billing_summary.invoice_sub_total_amt}`,
    },
    {
      label: "Discount Percent",
      value: `${data.billing_summary.discount_percent}%`,
    },
    {
      label: "Discount Amount",
      value: `$${data.billing_summary.discount_amt}`,
    },
    {
      label: "Total Nett Amount",
      value: `$${data.billing_summary.invoice_total_nett_amt}`,
    },
  ];

  async function validateVoucher(voucherCode) {
    const validateVoucherUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.validateVoucher(accountId, voucherCode, "");
    await axios
      .get(validateVoucherUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        const isValid = res.data.result.data.is_valid;
        if (isValid) {
          setIsVoucherApplied(true);
          setVoucherAmt(res.data.result.data.voucher_value);
          alert("Voucher code applied successfully!");
        } else {
          alert(`${res.data.result.data.message}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const removeVoucher = (e) =>  {
    e.preventDefault();
    setVoucherCode("");
    setVoucherAmt(0);
    setIsVoucherApplied(false);
  }

  const handleApplyVoucher = (e) => {
    e.preventDefault();
    if (!voucherCode) {
      alert("Please enter a voucher code.");
      return;
    }
    validateVoucher(voucherCode);
  };

  async function updateInvoice() {
    let body = {
      org_id: process.env.REACT_APP_RESOURCE_OPENAPIPAAS_ORG_ID,
      voucher_syscode: 0,
      voucher_code: '0',
      voucher_amt: 0
    };
    if (voucherCode) {
      const getVoucherUrl =
        urls.resourceOwnerUrl +
        commonConfigs.apiUrls.getVoucher(voucherCode);
      await axios
        .get(getVoucherUrl, {
          headers: commonConfigs.resourceOwnerApiHeader,
        })
        .then((res) => {
          try {
            body = {
              voucher_syscode: res.data.result.data.voucher_syscode,
              voucher_code: voucherCode,
              voucher_amt: res.data.result.data.voucher_value,
              org_id: process.env.REACT_APP_RESOURCE_OPENAPIPAAS_ORG_ID,
            };
            localStorage.setItem("voucher", voucherCode);
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          setIsDisabled(false); // Enable the submit button if there's an error
          throw err;
        });
    } // add else if for discounts
    const updateInvoiceUrl = 
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.updateInvoice(data.txn_invoice_id);
    await axios.put(updateInvoiceUrl, body, {
      headers: commonConfigs.resourceOwnerApiHeader
    })
    .then((res) => {
      console.log(res.data.result.data, body)
      const amountAfterVoucher = res.data.result.data.invoice_total_nett_amt - body.voucher_amt;
      localStorage.setItem("txnId", res.data.result.data.txn_id);
      localStorage.setItem("amount", amountAfterVoucher);
      localStorage.setItem("invoiceId", data.txn_invoice_id);
      window.location.href = "/payments";
    })
    .catch((error) => {
      console.log("Error: ", error);
      setIsDisabled(false);
    })
  }
  async function handlePayment() {
    setIsDisabled(true);
    await updateInvoice();
  }

  return (
    <div
      style={{
        lineHeight: "1.6",
      }}
      className="w-full p-6 text-black text-left"
    >
      <div className="flex justify-start items-center mb-8 mt-2">
        <Link to="/subscriptions/invoices">
          <button
            type="button"
            className="hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 font-bold py-2 px-12 rounded-md"
          >
            Back
          </button>
        </Link>
      </div>
      <h2 className="font-bold mb-4" style={{ fontSize: "28px" }}>
        Invoice #{data.index}
      </h2>
      <section
        style={{
          borderBottom: "2px solid black",
          paddingBottom: "16px",
          marginBottom: "16px",
        }}
      >
        <h3
          className="font-bold underline mb-4 ml-4"
          style={{ fontSize: "24px" }}
        >
          Billing Summary
        </h3>
        <div className="ml-4" style={{ fontSize: "17px" }}>
          {billingFields.map((field, index) => (
            <p
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: index % 2 === 0 ? "white" : "transparent",
                padding: "2px",
              }}
            >
              <strong>{field.label}:</strong>
              <span>{field.value}</span>
            </p>
          ))}
        </div>
      </section>

      {data.txn_invoice_api_details.map((invoiceDetail, projectIndex) => (
        <section
          key={projectIndex}
          style={{
            borderBottom:
              projectIndex === data.txn_invoice_api_details.length - 1
                ? "none"
                : "2px solid black",
            paddingBottom: "16px",
            marginBottom: "16px",
            width: "100%", // Ensure the section takes up the full width
            boxSizing: "border-box",
          }}
        >
          {/* Project Name */}
          <h3
            className="font-bold underline mb-4 ml-4"
            style={{ fontSize: "24px" }}
          >
            {invoiceDetail.developer_proj_name}
          </h3>

          {/* Project Details */}
          <div className="ml-4" style={{ fontSize: "17px" }}>
            <div style={{ borderBottom: "2px dotted black" }}>
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                  padding: "4px",
                }}
              >
                <strong>Cumulative API Usage Count:</strong>
                <span>{invoiceDetail.developer_proj_cumulative_count}</span>
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "4px",
                }}
              >
                <strong>Total Nett Amount:</strong>
                <span>${invoiceDetail.invoice_nett_amt}</span>
              </p>
            </div>

            {/* Project API Usage */}
            <h4
              className="font-bold underline mb-4 mt-4 ml-8"
              style={{ fontSize: "24px" }}
            >
              Project API Usage
            </h4>

            {invoiceDetail.project_details.map((api, apiIndex) => {
              const usageFields = [
                { label: "API", value: api.project_name },
                {
                  label: "Subscription Plan",
                  value: api.api_plan || "N.A.",
                },
                {
                  label: "Variable Rate",
                  value: `$${api.api_plan_variable_rate.toFixed(2)}/Request`,
                },
                {
                  label: "API Usage Count",
                  value: api.developer_proj_count,
                },
                {
                  label: "API Variable Cost",
                  value: `$${api.variable_cost.toFixed(2)}`,
                },
              ];
              return (
                <div
                  key={apiIndex}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "4px",
                    borderBottom:
                      apiIndex === invoiceDetail.project_details.length - 1
                        ? "none"
                        : "2px dotted black",
                  }}
                  className="ml-8"
                >
                  {usageFields.map((field, index) => (
                    <p
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor:
                          index % 2 === 0 ? "white" : "transparent",
                        padding: "4px",
                      }}
                    >
                      <strong>{field.label}:</strong>
                      <span>{field.value}</span>
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
        </section>
      ))}
      <div
        style={{
          backgroundColor: "#FFF",
          paddingTop: "20px",
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingBottom: "3px",
          borderRadius: "10px",
          textAlign: "left",
          color: "black",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Small light shadow
        }}
      >
        <form>
          <div className="mt-1 sm:col-span-2 mb-8" style={{ color: "black" }}>
            <div
              className="flex justify-between items-center"
              style={{
                backgroundColor: "#EFEFEF",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              <div className="flex items-center">
                <label
                  htmlFor="voucher_code"
                  className="text-sm font-medium text-black mr-2 pl-2 font-xl"
                  style={{ textAlign: "left" }}
                >
                  Voucher Code:
                </label>
                <input
                  type="text"
                  name="voucher_code"
                  id="voucher_code"
                  value={voucherCode}
                  style={{
                    width: "500px",
                  }}
                  onChange={(e) => setVoucherCode(e.target.value)}
                  className={
                    !errors.voucher_code
                      ? "block pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                      : "block pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                  }
                  placeholder="Enter Voucher Code"
                  disabled={isVoucherApplied}
                />
              </div>

              <button
                onClick={isVoucherApplied ? removeVoucher : handleApplyVoucher}
                className={`w-44 px-4 py-2 ${
                  isVoucherApplied
                    ? "text-red-500 hover:bg-red-500 hover:text-white outline outline-red-500"
                    : "text-blue-500 hover:bg-blue-500 hover:text-white outline outline-blue-500"
                } rounded-md`}
              >
                {isVoucherApplied ? "Remove Voucher" : "Apply Voucher"}
              </button>
            </div>

            <div className="flex justify-between items-center mt-4">
              <p className="block text-sm font-medium font-xl text-black mt-2">
                Total Nett Amount: $
                {data.billing_summary.invoice_total_nett_amt.toFixed(2)}
              </p>

              <button
                type="submit"
                className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 mt-2 font-bold py-2 px-20 rounded-md ml-5"
                disabled={isDisabled}
                onClick={handlePayment}
              >
                Pay
              </button>
            </div>

            {isVoucherApplied && (
              <p className="block text-s font-medium text-gray-500">
                eVoucher: - ${voucherAmt}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
