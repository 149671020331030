import { useEffect, useMemo, useState } from "react";
import Spinner from "../../../../../commons/components/spinner";
import Table from "../../../../../commons/components/table";
import { Link, useNavigate } from "react-router-dom";
import { commonConfigs, urls } from "../../../../../config/config";
import axios from "axios";
import { getUser } from "../../../../../services/tokenService";

export default function ProductionPricingPlanLayout() {
  const [productionPlans, setProductionPlans] = useState();
  const [productionData, setProductionData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const ssoId = getUser()?.sso_id;
  const getProductionPricePlansUrl =
    urls.resourceOwnerUrl + commonConfigs.apiUrls.getProductionPlans();
  const getAllProductionDeveloperProjectsUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.GetAllDeveloperProjects(ssoId, "production");
  const navigate = useNavigate();
  async function getProductionPlans() {
    await axios
      .get(getProductionPricePlansUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        const mockData = [
          {
            api_plan: "payeatfwd",
            variable_rate: "$0.0015",
            api_plan_description: "API for managing menus, orders, and inventory in foodservice",
          },
          {
            api_plan: "foodcanopy",
            variable_rate: "$0.0002",
            api_plan_description: "API for managing menus, orders, and inventory in foodservice",
          },
          {
            api_plan: "foodservices",
            variable_rate: "$0.0025",
            api_plan_description: "API for managing menus, orders, and inventory in foodservice",
          },
        ]
        setProductionPlans(mockData);
        // setProductionPlans(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getProductionDeveloperProjects() {
    await axios
      .get(getAllProductionDeveloperProjectsUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        setProductionData(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getProductionPlans().catch((err) => {
      console.log("err for production plans: " + err);
    });
    getProductionDeveloperProjects();
  }, []);

  const columns = useMemo(() => [
    {
      Header: "Project API",
      accessor: "api_plan",
    },
    {
      Header: "Variable Rate",
      accessor: "variable_rate",
    },
    {
      Header: "Description",
      accessor: "api_plan_description",
    },
  ]);

  const onHandleGetStarted = () => {
    const disableButton = productionData.some(
      (project) => project.developer_proj_agreement !== 1
    );
    if (disableButton) {
      setIsDisabled(disableButton);
      alert("You have not signed the agreement!");
    } else {
      navigate("/projects/create", {
        state: {
          presetEnvironment: { value: "1", label: "Production" },
          isProd: 1,
        },
      });
    }
  };

  return (
    <>
      <div className="w-full">
        {productionPlans != null ? (
          <>
            <Table
              columns={columns}
              data={productionPlans}
              filters={columns.map((column) => column.accessor)}
            />
            {/* <Link to="/projects/create"> */}
            <button
              type="button"
              onClick={onHandleGetStarted}
              className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-md"
              style={{ float: "right", marginTop: "-50px" }}
            >
              Get Started
            </button>
            {/* </Link> */}
          </>
        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
