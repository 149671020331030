import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../components/sandboxCard";
import { urls, commonConfigs } from "../../../config/config";
import ComingSoonModal from "../../../commons/components/comingSoonModal";
import HomePageCard from "../components/homePageCard";
import Rocket from "../../../assets/Rocket.svg";
import Certificate from "../../../assets/Certificate.svg";
import BrainstormSkill from "../../../assets/Brainstorm Skill.svg";

const HomeLayout = () => {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [comingSoonDate, setComingSoonDate] = useState(null);
  const navigate = useNavigate();
  const ssoId = localStorage.getItem("ssoId");
  const getAllSandboxesUrl =
    urls.sandboxUrl + commonConfigs.apiUrls.GetAllDeveloperProjects(ssoId, 'staging');
  async function getSandboxes() {
    await axios
      .get(getAllSandboxesUrl, { headers: commonConfigs.sandboxApiHeader })
      .then((res) => {
        // console.log(res.data.result.data);
        // console.log(res.data.result.data.length);
        const sandboxProj = res.data.result.data.find((project) =>
          project.access.some((access) => access.project_name === "sandbox_nets")
        );
        setData(sandboxProj);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSandboxClick = () => {
    window.location.href = '/sandbox/create'; 
  };

  const handleComingSoonClick = (date) => {
    setComingSoonDate(date);
    setOpen(true);
  };

  const handleGetCertificationClick = () => {
    navigate("/subscriptions/pricing-plan", { state: { tabValue: 0 } });
  };

  useEffect(() => {
    getSandboxes();
  }, []);

  if (!load) {
    return (
      <div style={{ width: "100%" }}>
        <div className="mt-4 desktop:space-x-8 mobile:pt-2 mobile:pb-4 mobile:space-x-2">
          {/* button for 'Create Project' */}
          {/* <Link to="/sandbox/create">
            <button
              type="button"
              className={`font-bold py-2.5 px-14 rounded-full mr-10 ${
                data.length >= 1
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700 text-white"
              }`}
              style={{ float: "right" }}
              disabled={data.length >= 1}
            >
              Create New Sandbox
            </button>
          </Link> */}

          {/* Display the project cards if data exists */}
          {/* {data.length > 0 ? (
            <div
              className="mt-12 flex flex-grow gap-x-2 justify-end pr-10"
              style={{ display: "grid", gridTemplateColumns: "2fr 2fr 2fr" }}
            >
              {data.map((item, index) => (
                <Card key={index} data={item} index={index} />
              ))}
            </div>
          ) : ( */}
            <>
              <div
                className="text-center p-5 rounded-2xl relative"
                style={{ backgroundColor: "#2b2d42", color: "white" }}
              >
                <h1 className="mt-4" style={{ fontSize: "36px" }}>Welcome Developers!</h1>
                <h3 className="mb-14 mt-4" style={{ fontSize: "27px" }}>
                  Empower Market Access And Social Mobility Today
                </h3>
              </div>
              <div
                className="flex justify-around items-center relative"
                style={{ marginTop: "-70px" }}
              >
                {data ? (
                  <HomePageCard data={data} icon={BrainstormSkill} onClick={handleSandboxClick}/>
                ) : (
                  <HomePageCard text="Explore NETS payments APIs" header="Sandbox" icon={BrainstormSkill} onClick={handleSandboxClick}/>
                )}
                <HomePageCard text="Get certified NETS-OPENAPIPAAS payments micro-credentials" header="UAT" icon={Certificate} onClick={() => handleGetCertificationClick()}/>
                <HomePageCard text="Go live with NETS-OPENAPIPAAS merchant payments APIs" header="Go Live" icon={Rocket} onClick={() => handleComingSoonClick("End May")}/>
              </div>
              {isOpen && (
                <ComingSoonModal setOpenModal={setOpen} comingSoonDate={comingSoonDate}/>
              )}
            </>
          {/* )} */}
        </div>
      </div>
    );
  }
};

export default HomeLayout;
